.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9100;
  padding: 15px 0;
  transition: all 0.3s;
  background: rgba(255, 255, 255, 0.85);
}
.navbar.scrolled {
  border-bottom: 1px solid #e1e1e1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.navbar .site-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .logo {
  display: flex;
  align-items: center;
  gap: 5px;
}
.navbar .logo img {
  width: 43px;
}
.navbar h4 {
  font-size: 22px;
  text-transform: uppercase;
  color: var(--secondary);
  font-weight: 600;
}
.navbar h4 span {
  color: var(--primary);
}

.navbar .left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar .right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.navbar .menu {
  display: flex;
  align-items: center;
  gap: 30px;
}
.navbar .menu a {
  color: #111;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.navbar .menu a hr {
  width: 0;
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: auto;
  right: 0;
  background: #444;
  transition: all 0.3s;
}
.navbar .menu a:hover hr {
  width: 100%;
  left: 0;
  right: auto;
  background: var(--primary);
}
.navbar .menu a.active hr {
  width: 100%;
  background: var(--primary);
  left: 0;
  right: auto;
}
.navbar .menu a:hover,
.navbar .menu a.active {
  color: var(--primary);
}

.navbar button {
  padding: 10px 15px;
  font-size: 14px;
}
.navbar button:hover {
  filter: brightness(107%);
}

.navbar .mobile-btn {
  width: 38px;
  height: 38px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s;
}
.navbar .mobile-btn i {
  color: var(--primary);
  font-size: 20px;
}



@media (max-width: 1080px) {
  .navbar .menu {
    display: none;
  }
  .navbar .mobile-btn {
    display: flex;
  }
}

@media (max-width: 650px) {
  .contact-section .form-grid form {
    grid-template-columns: 1fr;
  }
}