.homepage {
  display: flex;
  flex-direction: column;
}

.home-section {
  padding: 100px 0;
  width: 100%;
}

/* Hero Section */
.homepage .hero-section {
  width: 100%;
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('app/assets/images/hero-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.homepage .hero-section .hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.homepage .hero-section .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  gap: 10px;
}
.homepage .hero-section .text-container h1 {
  font-size: 60px;
  color: var(--secondary);
  line-height: 1.2;
}
.homepage .hero-section .text-container h5 {
  font-size: 19px;
  color: var(--secondary);
  font-weight: 500;
  line-height: 1.7;
}
.homepage .hero-section .text-container .btn-group {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.homepage .hero-section .text-container button.main-btn {
  box-shadow: var(--primaryShadow);
}
.homepage .hero-section .text-container button i {
  transition: all 0.3s ease;
}
.homepage .hero-section .text-container button:hover i {
  transform: translateX(7px);
}
.homepage .hero-section .img-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.homepage .hero-section .img-container img {
  height: calc(100vh - 90px);
  width: 100%;
  object-fit: contain;
}

/* Themes section */
.themes-section .themes-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.themes-section .themes-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 60px;
}
.themes-section .themes-header h6 {
  font-size: 15px;
  color: var(--secondary);
  font-weight: 500;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 5px;
  transition: all 0.3s;
}
.themes-section .themes-header h6:hover,
.themes-section .themes-header h6.active {
  background: var(--bgPrimary);
}
.themes-section .themes-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  width: 100%;
}
.themes-section .theme-item {
  height: 400px;
  width: 320px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 7px rgba(0,0,0,0.05);
  transition: all 0.3s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
}
.themes-section .theme-item.show {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.themes-section .theme-item .inner-item {
  width: 100%;
}
.themes-section .theme-item img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
.themes-section .theme-item .inner-item,
.themes-section .theme-item:hover img.pre-img {
  opacity: 0;
  visibility: hidden;
}
.themes-section .theme-item:hover .inner-item {
  opacity: 1;
  visibility: visible;
  animation: scrollUp 6s linear 0.5s;
}
@keyframes scrollUp {
  0% { transform: translateY(0) }
  90% { transform: translateY(-100%) }
  100% { transform: translateY(-100%) }
}
.themes-section .theme-item:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.4) 100%);
  z-index: 10;
}
.themes-section .theme-item .theme-info {
  position: absolute;
  bottom: 0;
  left: 30px;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: opacity 0.3s, visibility 0.3s, bottom 0.5s;
  transition-delay: 0.3s;
  z-index: 20;
}
.themes-section .theme-item .theme-info h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.themes-section .theme-item .theme-info h6 {
  color: #eee;
  font-size: 15px;
  font-weight: 500;
}
.themes-section .theme-item:hover .theme-info {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}
.themes-section .btn-group {
  margin-top: 60px;
}


/* Services Section  */
.services-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services-section .services-grid {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 50px;
  width: 100%;
}
.services-section .service-item {
  width: 390px;
  border-radius: 10px;
  transition: all 0.3s;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.services-section .service-item:hover {
  box-shadow: 0 0px 25px 2px rgba(108, 25, 217, 0.05);
}
.services-section .service-item img {
  margin-bottom: 30px;
  width: 100%;
  height: 270px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.14);
}
.services-section .service-item h5 {
  font-size: 20px;
  color: var(--secondary);
  font-weight: 600;
  margin-bottom: 10px;
}
.services-section .service-item p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--grayText);
  opacity: 0.8;
  line-height: 1.7;
}
.services-section .service-item button {
  margin-top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, top 0.5s;
  top: 20px;
}
.services-section .service-item:hover button {
  opacity: 1;
  visibility: visible;
  top: 0;
}


/* About Section */
.about-section .about-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 40px;
}
.about-section .about-flex .text-container {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.about-section .about-flex .text-container p {
  margin-top: 30px;
  line-height: 1.8;
  font-size: 17px;
  color: var(--grayText);
}
.about-section .about-flex .text-container .btn-group {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.about-section .about-flex .play-video-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  padding: 5px 15px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.about-section .about-flex .play-video-btn:active {
  transform: scale(0.93);
}
.about-section .about-flex .play-video-btn h6 {
  font-size: 17px;
  color: var(--secondary);
  font-weight: 500;
}
.about-section .about-flex .play-video-btn:hover {
  background: var(--bgPrimary);
}
.about-section .about-flex .play-video-btn i {
  font-size: 35px;
  color: var(--secondary);
}
.about-section .about-flex .img-container {
  width: 52%;
  padding: 20px;
}
.about-section .about-flex .img-container .img-content {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}
.about-section .about-flex .img-container .img-content::after {
  content: "";
  position: absolute;
  top: -40px;
  left: -40px;
  width: 100%;
  height: inherit;
  border-radius: 10px;
  background: var(--secondary);
  opacity: 0.05;
  z-index: 1;
  filter: blur(2px);
}
.about-section .about-flex .img-container img {
  filter: drop-shadow(15px 15px 13px rgba(0,0,0,0.1));
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 10px;
}
.app-modal-container .home-video-modal {
  background: none;
  box-shadow: none;
  width: 770px;
}
.app-modal-container .home-video-modal header {
  display: none;
}
.app-modal-container .home-video-modal section {
  padding: 0;
  max-height: auto;
  background: none;
}
.app-modal-container .home-video-modal iframe {
  width: 100%;
  height: 500px;
  border: none;
  box-shadow: var(--shadow);
}


/* Testimonials Section */
.homepage .testimonials-section {
  display: flex;
  flex-direction: column;
}
.testimonials-section .testimonials-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
}
.testimonials-section .testimonials-masonry {
  margin-top: 70px;
  columns: 3;
  column-gap: 10px;
}
.testimonials-section .testimonial-item {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  transition: all 0.3s;
}
.testimonials-section .testimonial-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.testimonials-section .testimonial-item i {
  color: var(--primary);
  font-size: 25px;
}
.testimonials-section .testimonial-item p {
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary);
  line-height: 1.6;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
.testimonials-section .testimonial-item img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.testimonials-section .testimonial-item .item-info {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.testimonials-section .testimonial-item h6 {
  font-size: 16px;
  color: var(--secondary);
  font-weight: 600;
}
.testimonials-section .testimonial-item small {
  color: var(--grayText);
  font-weight: 500;
  font-size: 14px;
}



/* Customizer Section */
.customizer-section {
  background: var(--primaryGradient);
}
.customizer-section .customizer-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 50px;
  flex-wrap: wrap;
}
.customizer-section .customizer-flex h3 {
  font-size: 47px;
  color: #fff;
}
.customizer-section .customizer-flex .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 45%;
}
.customizer-section .customizer-flex p {
  color: #fff;
  font-size: 21px;
}
.customizer-section .customizer-flex button {
  padding: 15px 30px;
  font-size: 17px;
}
.customizer-section .customizer-flex button i {
  transition: all 0.3s;
}
.customizer-section .customizer-flex button:hover i {
  transform: translateX(7px);
}


/* Subscribe Section */
.subscribe-section .subscribe-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.subscribe-section .subscribe-flex .input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subscribe-section .subscribe-flex .input-container .top {
  display: flex;
  align-items: center;
  margin-top: 60px;
  width: 600px;
}
.subscribe-section .subscribe-flex .input-container .appInput {
  width: calc(100% - 75px);
}
.subscribe-section .subscribe-flex .input-container input {
  padding: 10px 25px;
  height: 65px;
  border-radius: 0;
  border: none;
  background: #f1f1f1;
  font-size: 16px;
  border-radius: 10px 0 0 10px;
}
.subscribe-section .subscribe-flex .input-container input::placeholder {
  color: #aaa;
}
.subscribe-section .subscribe-flex .input-container button {
  border-radius: 0;
  height: 65px;
  width: 75px;
  border-radius: 0 10px 10px 0;
}
.subscribe-section .subscribe-flex .input-container button i {
  font-size: 20px;
}
.subscribe-section .subscribe-flex small {
  font-size: 14px;
  color: var(--secondary);
}
.subscribe-section .subscribe-flex small.error {
  color: var(--red);
}



@media (max-width: 1250px) {
  .homepage .hero-section {
    height: auto;
  }
  .homepage .hero-section .hero-content {
    flex-direction: column;
    align-items: center;
  }
  .homepage .hero-section .text-container {
    text-align: center;
    align-items: center;
    margin: 0;
    width: 90%;
  }
  .homepage .hero-section .img-container {
    width: 500px;
    margin-top: 20px;
  }
  .homepage .hero-section .img-container img {
    height: 420px;
  }
  .homepage .hero-section .text-container h1 {
    font-size: 3rem;
  }

}

@media (max-width: 1080px) {
  .homepage .hero-section {
    overflow-x: hidden;
  }
  .homepage .about-section {
    margin-top: 50px;
  }
  .about-section .about-flex {
    flex-direction: column;
    gap: 50px;
  }
  .about-section .about-flex .about-text {
    margin-top: 60px;
    width: 550px;
  }
  .about-section .about-flex > div {
    width: 100%;
  }
  .about-section .about-flex .about-text p {
    font-size: 16px;
  }
  .services-section .services-grid {
    width: 95%;
  }
  .testimonials-section .testimonial-item {
    margin: 0 auto;
    width: 85%;
  }
  .projects-section .projects-grid {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
  .testimonials-section .testimonials-content .carousel-container {
    width: 100%
  }
  .customizer-section .customizer-flex {
    flex-direction: column;
    align-items: center;
    gap: 70px;
  }
  .customizer-section .customizer-flex .left {
    text-align: center;
    align-items: center;
  }
}

@media (max-width: 600px) { 
  .homepage .hero-section .text-container .btn-group {
    flex-direction: column;
    gap: 20px;
  }
  .about-section .about-flex .btn-group {
    flex-wrap: wrap;
  }
  .testimonials-section .testimonial-item .text-box p {
    width: 100%;
  }
  .testimonials-section .testimonial-item {
    width: 92%;
  }
  .testimonials-section .app-carousel .carousel-arrows {
    position: relative;
    transform: translateY(0);
    top: auto;
    margin: auto;
    margin-top: 10px;
    width: 80%;
  }
  .testimonials-section .bubbles-container {
    transform: translateY(-90px);
  }
  .about-section .about-flex .about-text {
    width: 90%;
  }
  .services-section .services-grid {
    grid-template-columns: 1fr;
  }
  .projects-section .projects-grid {
    grid-template-columns: 1fr;
  }
  .projects-section .projects-grid .project-item {
    width: 100%;
  }
  .subscribe-section .subscribe-flex img {
    object-fit: contain;
    height: 300px;
  }
}

@media (max-width: 400px) {
  .about-section .about-flex .about-text {
    min-width: auto;
    width: 90%;
  }
}