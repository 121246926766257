.section-titles {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
}
.section-titles.center {
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.section-titles.center h6,
.section-titles.center h2,
.section-titles.center p {
  text-align: center;
}
.section-titles h6 {
  font-size: 17px;
  font-weight: 600;
  color: var(--secondary);
  margin-bottom: 5px;
}
.section-titles h2 {
  font-size: 40px;
  margin-bottom: 13px;
  color: var(--secondary);
}
.section-titles p {
  font-size: 17px;
  color: #999;
  font-weight: 500;
}