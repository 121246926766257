.floating-bubble {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
}
.floating-bubble .icon-container {
  box-shadow: var(--primaryShadow);
}
.floating-bubble .icon-container:hover {
  filter: brightness(1.1);
}
.chat-container .rsc-container {
  font-family: inherit;
}
.chat-container {
  position: fixed;
  bottom: 105px;
  right: 30px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px) scale(0.9);
  transition: all 0.4s;
}
.chat-container.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) scale(1);
}
.chat-container .rsc-header,
.chat-container .rsc-ts-bubble,
.chat-container button:not(.rsc-submit-button) {
  background: var(--primary)!important;
}
.chat-container .rsc-ts-bubble {
  color: #fff;
  max-width: 65%;
}