.sidebar {
  background: #fff;
  height: 100vh;
  width: 300px;
  max-width: 100%;
  overflow-y: auto;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--morphShadow);
  z-index: 9300;
  transition: opacity 0.3s, left 0.7s, visibility 0.3s;
}

.sidebar .top {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 10px 15px;
}

.sidebar .menu {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 100px;
}
.sidebar .menu h6 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  padding: 10px;
  transition: all 0.3s;
  color: var(--secondary);
}
.sidebar .menu h6 span {
  transition: all 0.3s;
}
.sidebar .menu h6 hr {
  width: 30px;
  height: 2px;
  background: var(--secondary);
  position: absolute;
  left: -30px;
  opacity: 0;
  transition: opacity 0.3s, left 0.4s;
  transition-delay: 0.07s;
}
.sidebar .menu h6:hover span {
  transform: translateX(25px);
}
.sidebar .menu h6:hover hr {
  left: -5px;
  opacity: 1;
}

.sidebar .bottom {
  padding: 10px 15px;
  padding-bottom: 15px;
}
.sidebar .bottom button {
  width: 100%;
  padding: 10px;
}

.close-sidebar {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 25px;
  color: var(--secondary);
  cursor: pointer;
  display: none;
}

.sidebar .appInput {
  display: none;
}

@media (max-width: 1080px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: -200px;
    opacity: 0;
    display: flex;
    visibility: hidden;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  }
  .sidebar.show-mobile {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  .close-sidebar {
    display: block;
  }
  .sidebar .appInput {
    display: flex;
  }
}