html, body {
  margin: 0;
  padding: 0;
  font-family: 'Lato';
  background: #fff;
}    
  
:root { 
  --primary: #6c19d9;
  --lightPrimary: #ac70ff;
  --extraLightPrimary: #d4b8ff;
  --bgPrimary: #f0e6ff;
  --secondary: #242F65;
  --lightSecondary: #3c4160;
  --ternary: #c919d9;
  --red: #ff4d4d;
  --orange: #ff5e08;
  --lightRed: #ffe2e2;
  --bg: #F3F3F3;
  --grayText: #696e93;
  --darkGrayText: #3c4160;
  --lightGrayText: #D5D7E5;
  --inputBg: #f0f2f5;
  --grayContainer: #e7e7e7;
  --lightInputBg: #F6F8FA;
  --darkInputBg: rgb(219, 223, 231);
  --primaryTrans: rgba(108, 25, 217, 0.1);
  --primaryShadow: 0 10px 12px 2px var(--primaryTrans);
  --morphShadow: 0 0 6px 2px rgba(23, 143, 255, 0.09);
  --primaryGradient: linear-gradient(130deg, var(--primary) 0%, var(--ternary) 100%);
  --inputBorder: #e4ecfb;
  --tableBorder: #d5deef;
  --darkBg: #22272B;
  --inputPlaceholderColor: #c5cee1;
  --shadow: 0 5px 10px rgba(0,0,0,0.1);
  --cardShadow: 0 0px 2px rgba(0,0,0,0.06);
  --cardShadowHover: 0 2px 4px 1px rgba(0,0,0,0.1);
  --newCardShadow: 0 0 3px 1px #e4ecfb;
  --navbarHeight: 60px;
  --sidebarWidth: 110px;
  --rightBarWidth: 300px;
  --siteGridWidth: 1350px;
  --maxPageWidth: 1080px;
}  

* {
  position: relative;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
::selection {
  background: var(--primary);
  color: #fff;
}

.site-grid {
  width: var(--siteGridWidth);
  margin: 0 auto;
}
.spacer {
  height: 50px;
}
img {
  object-fit: cover;
  max-width: 100%;
  user-select: none;
}
button {
  outline: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 40px;
  transition: all 0.2s;
  padding: 12px 20px;
  font-size: 16px;
  color: #fff;
  background: var(--primary);
}
button:active {
  transform: scale(0.98);
  filter: brightness(90%);
}
button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: auto;
}
.accent {
  color: var(--primary);
  font-weight: 500;
}
a { 
  text-decoration: none;
  color: #111;
}
.linkable {
  cursor: pointer;
}
a.linkable:hover {
  text-decoration: underline;
}
input, select, textarea {
  padding: 7px 10px;
  border: 1px solid var(--inputBorder);
  border-radius: 5px;
  width: 100%;
  color: var(--darkGrayText);
  outline: none;
  font-size: 14px;
  transition: all 0.3s;
  font-family: inherit;
} 
textarea {
  resize: vertical;
}
input:-webkit-autofill, 
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}
input::placeholder, textarea::placeholder {
  color: var(--inputPlaceholderColor);
}
input:disabled {
  background: #f1f1f1
}
select {
  background: #fff;
}
iframe {
  border-radius: 10px;
}
hr {
  border: none;
}
.capitalize, .cap {
  text-transform: capitalize
}
.uppercase, .upper {
  text-transform: uppercase
}
.lowercase {
  text-transform: lowercase
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}
.bold {
  font-weight: 700;
}
.commoninput h6 {
  cursor: auto;
}
.img-skeleton {
  width: 95%;
  height: 95%;
  border-radius: 5px;
  animation: skeleton 2s infinite ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@keyframes skeleton {
  0% { background: #f1f1f1; }
  50% { background: #ddd; }
  100% { background: #f1f1f1; }
}

.error-boundary {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error-boundary h1 {
  font-size: 40px;
  color: #111;
}
.error-boundary h6 {
  font-size: 14px;
  color: #555;
  margin-bottom: 40px;
}
.error-boundary img {
  width: 400px;
}
.error-boundary button {
  padding: 15px 25px;
  font-size: 16px;
  color: #fff;
  background: var(--primary);
}
.error-boundary button:hover {
  filter: brightness(110%);
}
.error-boundary button i {
  margin-right: 8px;
}


@media (max-width: 1370px) {
  .site-grid {
    width: 95%;
    padding: 0;
  }
}

@media (max-width: 1080px) {
  .site-grid {
    width: 97%;
  }
}