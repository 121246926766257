.app-container {
  
}

.mobile-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9200;
  display: none;
}

@media (max-width: 1080px) {
  .mobile-sidebar-overlay {
    display: block;
  }
}