.app-modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0,0,0, 0.5);
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}
.app-modal-container.show {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.app-modal-container .app-modal {
  width: 600px;
  border-radius: 5px;
  box-shadow: 0 0 17px 2px rgba(0,0,0,0.17);
  transform: scale(0.9);
  transition: 0.2s;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.app-modal-container.show .app-modal {
  transform: scale(1);
}
.app-modal-container .app-modal > header {
  width: 100%;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.app-modal-container .app-modal > header h4 {
  font-size: 18px;
  font-weight: 700;
  color: var(--grayText);
}
.app-modal-container .app-modal > header .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  transition: all 0.2s;
  cursor: pointer;
  background: #eee;
}
.app-modal-container .app-modal > header .icon-container i {
  color: #aaa;
  font-size: 20px;
}
.app-modal-container .app-modal > header .icon-container:hover {
  filter: brightness(0.96);
}

.app-modal-container .app-modal > section {
  width: 100%;
  padding: 20px 30px;
  max-height: calc(90vh - 130px);
  overflow-y: auto;
}

.app-modal-container .app-modal > footer {
  padding: 15px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.app-modal-container .app-modal > footer button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 650px) {
  .app-modal-container .app-modal {
    width: 92%;
  }
  .app-modal-container .app-modal > section {
    padding: 15px;
  }
  .app-modal-container .app-modal > header {
    padding: 10px 15px;
  }
}