.app-portal {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#root {
  z-index: 1;
}

.app-tooltip {
  position: fixed;
  z-index: 99000;
  background: green;
  opacity: 0.8;
  padding: 5px;
  display: flex;
  align-items: center;
  min-width: 200px;
}