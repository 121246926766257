.footer {
  padding: 70px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f1f1f1;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.footer-grid .column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-grid .column.side-column {
  gap: 15px;
}
.footer-grid .column .logo img {
  width: 50px;
  margin-bottom: 5px;
}
.footer-grid .column .logo h4 {
  color: var(--secondary);
  font-size: 20px;
}
.footer-grid .column small {
  margin-top: 5px;
  color: var(--secondary);
  font-weight: 500;
  font-size: 14px;
}
.footer-grid .column h5 {
  font-size: 18px;
  color: var(--secondary);
  margin-bottom: 20px;
  font-weight: 600;
}
.footer-grid .column a {
  color: var(--secondary);
  font-size: 15px;
  font-weight: 500;
}
.footer-grid .column a:hover {
  text-decoration: underline;
}
.footer .socials {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 35px;
}
.footer .credits {
  grid-column: 1/-1;
  margin-top: 30px;
  font-size: 15px;
  color: var(--secondary);
}